<template>
  <div class="card">
    <button
      class="audit-thumb btn rounded-0 w-100 border-0 p-2 fw-normal text-start"
      @click="emit('select')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="flex-shrink-0"><i class="bi fs-5" :class="iconClass" /></div>
        <div class="flex-grow-1 mx-2 mw-0 lh-md">
          <div v-if="entityLabel">{{ entityLabel }}</div>
          <div>{{ outcome || 'No Outcome' }}</div>
          <div>{{ formatISOStringDate(auditDate) }}</div>
          <div class="text-secondary text-nowrap text-truncate">{{ comment }}</div>
        </div>
        <div class="flex-shrink-0">
          <i class="bi-chevron-right" />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>
<script setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useProjectsStore } from '../stores/projects';
import { formatISOStringDate } from '../utilities/date';

const emit = defineEmits(['select']);

const props = defineProps({
  comment: {
    type: String,
    default: '',
  },
  auditDate: {
    type: String,
    default: '',
  },
  outcome: {
    type: String,
    default: '',
  },
  isComplete: {
    type: Boolean,
    default: false,
  },
  isPass: {
    type: Boolean,
    default: false,
  },
  isResolved: {
    type: Boolean,
    default: false,
  },
  whatImprovementOptionEvaluationId: {
    type: String,
    default: '',
  },
  whatLodgementId: {
    type: String,
    default: '',
  },
  whatMeasureId: {
    type: String,
    default: '',
  },
  whatRetrofitDesignId: {
    type: String,
    default: '',
  },
  whatSupportingDocumentId: {
    type: String,
    default: '',
  },
});

const iconClass = computed(() => {
  const outcome = props.outcome?.toLowerCase() || '';
  if (props.isResolved || (props.isComplete && (props.isPass || outcome.includes('pass')))) {
    return 'bi-check-circle text-success';
  }
  if (props.isComplete) return 'bi-x-circle text-danger';
  return 'bi-question-circle text-warning';
});

const { project } = storeToRefs(useProjectsStore());

const entityLabel = computed(() => {
  if (props.whatSupportingDocumentId) {
    return project.value?.documents.find((d) => d.documentId === props.whatSupportingDocumentId)
      ?.documentType;
  }
  if (props.whatImprovementOptionEvaluationId) {
    return project.value?.improvementOptionEvaluations.find(
      (ioe) => ioe.improvementOptionEvaluationId === props.whatImprovementOptionEvaluationId
    )?.reference;
  }
  if (props.whatLodgementId) {
    const lodgement = project.value?.lodgements.find(
      (l) => l.lodgementId === props.whatLodgementId
    );
    const parts = [lodgement?.certificateNumber];
    if (props.whatMeasureId) {
      parts.push(lodgement?.measures.find((m) => m.measureId === props.whatMeasureId)?.measureType);
    }
    return parts.filter((i) => i).join(' – ');
  }
  return null;
});
</script>

<style lang="scss" scoped>
.audit-thumb {
  transition: 100ms ease box-shadow, 100ms ease border-color;
  &:hover {
    border-color: $gray-500;
    box-shadow: $box-shadow-sm;
  }
}
</style>
