<template>
  <div class="badge rounded-pill" :class="[colourClass, sizeClass]" ref="el">
    {{ text }}
    <span v-if="showPopoverIcon" class="bi bi-info-circle ms-1" :class="[sizeClass]"></span>
  </div>
</template>

<script setup>
import { Popover as bootstrapPopover } from 'bootstrap';
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { toTitleCase } from '../utilities/case';

const props = defineProps({
  status: {
    type: String,
    default: 'unknown',
  },
  type: {
    type: String,
    default: '',
  },
  small: {
    type: Boolean,
    default: false,
  },
  popover: {
    type: String,
    default: '',
  },
  showPopoverIcon: {
    type: Boolean,
    default: false,
  },
});

const el = ref(null);
let popover = null;

onMounted(() => {
  if (el.value && props.popover) {
    popover = new bootstrapPopover(el.value, {
      container: 'body',
      trigger: 'click hover focus',
      placement: 'bottom',
      content: props.popover,
      offset: [0, 2],
      html: true,
    });
  }
});

onBeforeUnmount(() => popover?.dispose());

const colourClass = computed(() => {
  if (props.type) return `bg-${props.type}`;
  return (
    {
      accepted: 'bg-success',
      appointmentrequired: 'bg-warning',
      complete: 'bg-success',
      current: 'bg-warning',
      draft: 'bg-warning',
      escalated: 'bg-danger',
      escalatedtocompliance: 'bg-danger',
      evaluator: 'text-muted bg-light',
      fail: 'bg-danger',
      incomplete: 'bg-danger',
      inprogress: 'bg-primary',
      ncmajor: 'bg-danger',
      ncminor: 'bg-warning',
      needsevidence: 'bg-warning',
      new: 'bg-warning',
      ongoing: 'bg-warning',
      pass: 'bg-success',
      pending: 'bg-warning',
      rejected: 'bg-danger',
      requiresreview: 'bg-warning',
      resolved: 'bg-success',
      review: 'bg-warning',
      reviewed: 'bg-primary',
      submitted: 'bg-success',
      superseded: 'bg-primary',
      unresolved: 'bg-warning',
      cat1: 'bg-danger',
      due: 'bg-danger',
      onsite: 'bg-warning',
      overdue: 'bg-danger',
      escalatedtosp: 'bg-danger',
      escalatedtoassurance: 'bg-danger',
      ['desktop:needsevidence']: 'bg-warning',
      ['onsite:needsevidence']: 'bg-warning',
      ['noaccess']: 'bg-light text-secondary',
    }[props.status?.replace(/ /g, '').toLowerCase()] || 'bg-secondary'
  );
});

const sizeClass = computed(() => {
  return props.small ? 'small' : '';
});

const text = computed(
  () =>
    ({
      appointmentrequired: 'Appointment Required',
      complete: 'Complete',
      current: 'Current',
      default: '',
      draft: 'Draft',
      escalatedtocompliance: 'Escalated',
      incomplete: 'Incomplete',
      inprogress: 'In Progress',
      new: 'New',
      ncmajor: 'Non-Compliance (Major)',
      ncminor: 'Non-Compliance (Minor)',
      notdetermined: 'Not Determined',
      ongoing: 'Ongoing',
      resolved: 'Resolved',
      superseded: 'Superseded',
      escalatedtosp: 'Escalated to SP',
    }[props.status?.replace(/ /g, '').toLowerCase()] || toTitleCase(props.status || 'unknown'))
);
</script>

<style lang="scss" scoped>
.small {
  font-size: 0.6875rem;
}
</style>
