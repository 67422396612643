<template>
  <div class="py-3 px-3 d-flex justify-content-between align-items-center">
    <div class="form-label mb-0">{{ audits.length }} audit{{ audits.length === 1 ? '' : 's' }}</div>
    <StatusBadge :status="status" class="" />
  </div>
  <AuditDesktopSummarySection v-for="section of sections" :key="section.name" v-bind="section" />
  <AuditDesktopSummarySection
    v-for="section of measureSections"
    :key="section.title"
    v-bind="section"
  />
</template>

<script setup>
import { computed } from 'vue';
import StatusBadge from './StatusBadge.vue';
import AuditDesktopSummarySection from './AuditDesktopSummarySection.vue';

const props = defineProps({
  audits: {
    type: Array,
    required: true,
  },
  auditableSections: {
    type: Object,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
});

const sections = computed(() =>
  props.auditableSections
    .filter((s) => s.summary)
    .map((s) => ({
      title: s.title,
      link: s.link,
      audits: props.audits.filter((audit) => audit.auditingWhat === s.name),
    }))
);

const measureSections = computed(() => {
  const standalone = props.project.lodgementOfWorkStyle === 'Standalone';
  if (!standalone) {
    const planMeasures = props.project.mediumTermImprovementPlan.stages.flatMap(
      (stage) => stage.selectedMeasures
    );
    return planMeasures.map((planMeasure) => {
      const findMeasure = (lodgementMeasure) =>
        lodgementMeasure.improvementOptionEvaluationMeasureId ===
        planMeasure.improvementOptionEvaluationMeasureId;
      const lodgement = props.project.lodgements.find(
        (l) => l.isComplete && l.measures.find(findMeasure)
      );
      const measure = lodgement?.measures.find(findMeasure);
      return {
        title: `Measure – ${planMeasure.measureType}`,
        link: lodgement
          ? {
              name: 'lodgement-measure',
              params: { lodgementId: lodgement.lodgementId, measureId: measure?.measureId },
              query: {
                returnTo: 'project-lodgements',
              },
            }
          : null,
        audits: props.audits.filter(
          (audit) =>
            audit.auditingWhat === 'lodgement-measure' && audit.whatMeasureId === measure?.measureId
        ),
      };
    });
  } else {
    const allMeasures = props.project.lodgements.flatMap((lod) => lod.measures);
    return allMeasures.map((planMeasure) => {
      const findMeasure = (lodgementMeasure) =>
        lodgementMeasure.measureId === planMeasure.measureId;
      const lodgement = props.project.lodgements.find(
        (l) => l.isComplete && l.measures.find(findMeasure)
      );
      const measure = lodgement?.measures.find(findMeasure);
      return {
        title: `Measure – ${planMeasure.measureType}`,
        link: lodgement
          ? {
              name: 'lodgement-measure',
              params: { lodgementId: lodgement.lodgementId, measureId: measure?.measureId },
              query: {
                returnTo: 'project-lodgements',
              },
            }
          : null,
        audits: props.audits.filter(
          (audit) =>
            audit.auditingWhat === 'lodgement-standalone-measure' &&
            audit.whatMeasureId === measure?.measureId
        ),
      };
    });
  }
});

const status = computed(() => {
  if (!props.audits.length) return 'empty';
  let status = 'complete';
  for (const audit of props.audits) {
    if (!audit.isComplete) {
      status = 'ongoing';
      break;
    }
    if (audit.isComplete && !(audit.isResolved || audit.isPass || audit.outcome === 'Pass')) {
      status = 'incomplete';
    }
  }
  return status;
});
</script>
