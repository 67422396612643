<template>
  <LayoutAuth title="Two-Factor Authentication">
    <template #header>
      <p class="text-secondary lh-sm mt-2">
        <small>We would like to verify you.</small>
      </p>
    </template>
    <template #default="{ submitStage, submitButton }">
      <form @submit.prevent="submitStage(handleSubmit)">
        <div class="mt-4 d-flex justify-content-between">
          <Component :is="submitButton">Send SMS Verification Code</Component>
        </div>
      </form>
    </template>
    <template #footer>
      <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onChangeMobile"
        >Change Mobile Number</RouterLink
      >
      <span class="mx-2">•</span>
      <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onReset"
        >Back to Login</RouterLink
      >
    </template>
  </LayoutAuth>
</template>

<script setup>
import LayoutAuth from './LayoutAuth.vue';
import { useAuthStore } from '../stores/auth';

const auth = useAuthStore();

const handleSubmit = async () => {
  await auth.sendSmsCode();
};

const onChangeMobile = () => {
  auth.mobileNumber = null;
};

const onReset = () => {
  auth.$reset();
};
</script>
